// extracted by mini-css-extract-plugin
export var tariffPeriod = "tariffsPrice-module--tariffPeriod--DL9+R";
export var pricesWithSwitcher = "tariffsPrice-module--pricesWithSwitcher--Pa5yX";
export var pricesWithoutSwitcher = "tariffsPrice-module--pricesWithoutSwitcher--fmBzA";
export var switcher = "tariffsPrice-module--switcher--dR-Jb";
export var buttonSwitcher = "tariffsPrice-module--buttonSwitcher--RDsst";
export var activeButton = "tariffsPrice-module--activeButton--qoqWI";
export var priceValues = "tariffsPrice-module--priceValues--WeIFh";
export var tariffPriceSmall = "tariffsPrice-module--tariffPriceSmall--6yODm";
export var price = "tariffsPrice-module--price--AfwOx";
export var priceCurrent = "tariffsPrice-module--priceCurrent--pnzSd";
export var priceCurrentNote = "tariffsPrice-module--priceCurrentNote--TX0zz";
export var priceOld = "tariffsPrice-module--priceOld--rqn29";
export var priceInPricesList = "tariffsPrice-module--priceInPricesList--3l-yw";
export var monthInPricesList = "tariffsPrice-module--monthInPricesList--Axgpp";