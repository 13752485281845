import React, { useState } from 'react';
import cn from 'classnames';
import { yaEvent } from '../../helper/metrika';
import API from '../../api';

import Button from '../button/button';
import TariffsPrice from '../tariffsPrice/tariffsPrice';
import RequestModal from '../request-modal/requestModal';

import IconTariffsCheckmark from '../../assets/icons/tariffs-checkmark.svg';

import * as s from './tariffList.module.scss';

const TARIFFS = {
  base: [
    'Доступен всем зарегистрированным пользователям',
    'Доступен для 3-х пользователей из группы рекрутеров компании с ролью администратор и рекрутер в совокупности. Нет ограничений по количеству вакансий и кандидатов',
    'Возможность добавлять соискателей с\u00A0сайтов по\u00A0поиску работы в\u00A0один клик',
    'Встроенный календарь'
  ],
  standard: [
    'Нет ограничений по числу пользователей из группы рекрутеров компании для ролей администратор и рекрутер. Также нет ограничений для приглашенных пользователей с ролью заказчик, по числу вакансий и кандидатов',
    'Расширенный поиск резюме по\u00A0полям: возраст, ожидаемая\u00A0зарплата, статус кандидата и проч.',
    'Заявки на подбор персонала',
    'Возможность выгружать список\u00A0кандидатов (.csv) и скачивать резюме (.docx и .pdf)',
    'Индивидуальные настройки для каждой воронки найма',
    'Рассылки посредством почтового сервиса для групп кандидатов (приглашения на собеседования, отказы в найме и проч.)',
    'Сценарий звонка',
    'Автоматический сбор откликов соискателей с HeadHunter и Работы.ру',
    'Быстрая отправка сообщений на WhatsApp, Telegram и почту',
    'Интеграция с API HeadHunter',
    'Выгрузка отчета по воронке статусов в формате .xlsx'
  ],
  optimal: [
    'Нет ограничений по числу пользователей из группы рекрутеров компании для ролей администратор и  рекрутер. Также нет ограничений для приглашенных пользователей с ролью заказчик, по числу вакансий и кандидатов',
    'Публикация вакансий из сервиса на Работе.ру и HeadHunter',
    'Теги в карточках кандидатов для легкого поиска',
    'Контроль за сроком нахождения кандидата на этапе',
    'Возможность отправлять сообщения по электронной почте соискателям и получать ответы от них прямо в СберПодборе',
    'Формирование офферов подходящим кандидатам в сервисе и скачивание их в форматах PDF и .docx',
    'Расширенный поиск резюме по полям: возраст, ожидаемая зарплата, статус кандидата и проч.',
    'Заявки на подбор персонала',
    'Возможность выгружать список кандидатов (.csv) и скачивать резюме (.docx и .pdf)',
    'Индивидуальные настройки для каждой воронки найма',
    'Рассылки посредством почтового сервиса для групп кандидатов (приглашения на собеседования, отказы в найме и проч.)',
    'Сценарий звонка',
    'Автоматический сбор откликов соискателей с HeadHunter, Работы.ру и Avito Работа',
    'Быстрая отправка сообщений на WhatsApp, Telegram и почту',
    'Интеграция с API HeadHunter',
    'Выгрузка отчета по воронке статусов в формате .xlsx'
  ]
};

const tariffList = props => {
  const { page } = props;
  const isIndexPage = page === 'index';

  const [isOpen, setIsOpen] = useState(false);
  const [tariff, setTariff] = useState(null);

  const openModal = tariff => {
    yaEvent('LANDING_CLICK_APPLICATION');
    API.metric.dispatchEvent({
      event_id: 'LANDING_CLICK_APPLICATION'
    });

    setIsOpen(true);
    setTariff(tariff);
  };

  return (
    <div className={cn(s.tariffs, { [s.tariffsIndex]: isIndexPage })}>
      {isIndexPage && <h2 className={cn(s.tariffsTitle, 'h2')}>Тарифы</h2>}

      <RequestModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        tariff={tariff}
        className='tariffs-modal'
        formName={'tariff-list'}
      />

      <div className={s.tariffsBackground_1} />
      <div className={s.tariffsBackground_2} />


      <div className={s.tariffsCards}>
        <h3 className={s.tariffsSubtitle}>Специальные тарифы для компаний с одним рекрутером</h3>

        <div className={cn(s.tariffCard, { [s.tariffCardOnlyMain]: isIndexPage })}>
          <div className={s.tariffCardMainPart}>
            <div className={cn(s.tariffCardName, 'h2')}>
              Базовый<br/> функционал
            </div>

            <div className={s.tariffCardDescription}>
              Ведите базу соискателей, создавайте воронки найма,
              отслеживайте их эффективность и&nbsp;узкие места
            </div>

            <div className={s.tariffPrice}>
              <TariffsPrice tariff={'base'} />

              <Button
                onClick={() => yaEvent('pressed_submit_request')}
                text={'Зарегистрироваться'}
                className={s.tariffPriceButton}
                href="https://app.sberpodbor.ru/sign-up/company"
                noTitle
                newWindow
                large
              />
            </div>
          </div>

          {!isIndexPage && <>
            <ul>
              {TARIFFS.base.map((tariffProperty, index) => (
                <li className={s.tariffCardListItem} key={index}>
                  <IconTariffsCheckmark
                    className={s.tariffCardListItemIcon}
                  />
                  <span>{tariffProperty}</span>
                </li>
              ))}
            </ul>

            <div className={s.tariffCardActions}>
              <div className={s.tariffCardActionLink}>
                <a
                  className="text-blue"
                  onClick={() => yaEvent('pressed_button_pricing')}
                  href={
                    '/doc/Соглашение_оферта_об_использовании_сервиса_СберПодбор.pdf'
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Соглашение об использовании сервиса
                </a>
              </div>
            </div>
          </>}
        </div>

        <div className={cn(s.tariffCard, s.tariffCardStandardMini, { [s.tariffCardOnlyMain]: true })}>
          <div className={cn(s.tariffCardMainPart, s.tariffCardMainPartMini)}>
            <div className={cn(s.tariffCardName, 'h2')}>
              Стандартный<br/> тариф мини
            </div>

            <div className={s.tariffCardDescription}>
              Все опции тарифа «Стандартный», но&nbsp;с&nbsp;правом подключения только одного пользователя из группы рекрутеров с ролью главного администратора. Количество заказчиков не&nbsp;ограничено
            </div>

            <div className={s.tariffPrice}>
              <TariffsPrice tariff={'standard_mini'} className={'tariffPriceSmall'} />
              <Button
                onClick={() => openModal('standard_mini')}
                text={'Оставить заявку'}
                className={s.tariffPriceButton}
                noTitle
                large
              />
            </div>
          </div>
        </div>

        <div className={cn(s.tariffCard, { [s.tariffCardOnlyMain]: isIndexPage })}>
          <div className={s.tariffCardMainPart}>
            <div className={cn(s.tariffCardName, 'h2')}>
              Стандартный<br/> тариф
            </div>

            <div className={s.tariffCardDescription}>
              Экономьте время на рутинных задачах&nbsp;—&nbsp;от сбора заявок до подготовки отчетов
            </div>

            <div className={s.tariffPrice}>
              <TariffsPrice tariff={'standard'} />
              <Button
                onClick={() => openModal('standard')}
                text={'Оставить заявку'}
                className={s.tariffPriceButton}
                noTitle
                large
              />
            </div>
          </div>

          {!isIndexPage && <>
            <ul>
              {TARIFFS.standard.map((tariffProperty, index) => (
                <li className={s.tariffCardListItem} key={index}>
                  <IconTariffsCheckmark
                    className={s.tariffCardListItemIcon}
                  />
                  <span>{tariffProperty}</span>
                </li>
              ))}
            </ul>

            <div className={s.tariffCardActions}>
              <div className={s.tariffCardActionLink}>
                <a
                  className="text-blue"
                  onClick={() => yaEvent('pressed_button_pricing')}
                  href={
                    '/doc/СберПодбор_Лицензионный_договор_публичная_оферта_ООО_РДВ-софт.pdf'
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Полное описание тарифа
                </a>
              </div>
            </div>
          </>}
        </div>

        <div className={cn(s.tariffCard, s.tariffCardOptimalMini, { [s.tariffCardOnlyMain]: true })}>
          <div className={cn(s.tariffCardMainPart, s.tariffCardMainPartMini)}>
            <div className={cn(s.tariffCardName, 'h2')}>
              Оптимальный<br/> тариф мини
            </div>

            <div className={s.tariffCardDescription}>
                Все опции тарифа «Оптимальный», но&nbsp;с&nbsp;правом подключения только одного пользователя из группы рекрутеров с ролью главного администратора. Количество заказчиков не&nbsp;ограничено
            </div>

            <div className={s.tariffPrice}>
              <TariffsPrice tariff={'optimal_mini'} className={'tariffPriceSmall'} />
              <Button
                onClick={() => openModal('optimal_mini')}
                text={'Оставить заявку'}
                className={s.tariffPriceButton}
                noTitle
                large
              />
            </div>
          </div>
        </div>

        <div className={cn(s.tariffCard, { [s.tariffCardOnlyMain]: isIndexPage }, s.tariffCardWithDiscount)}>
          <div className={s.tariffCardMainPart}>
            <div className={cn(s.tariffCardName, 'h2')}>
              Оптимальный<br/> тариф
            </div>

            <div className={s.tariffCardDescription}>
              Автоматизируйте свой рекрутинг по максимуму: ускорьте согласования и формирование офферов для подходящих кандидатов
            </div>

            <div className={s.tariffPrice}>
              <TariffsPrice tariff={'optimal'} hasDiscount={true} />

              <Button
                onClick={() => openModal('optimal')}
                text={'Оставить заявку'}
                className={s.tariffPriceButton}
                noTitle
                large
              />
            </div>
          </div>

          {!isIndexPage && <>
            <ul>
              {TARIFFS.optimal.map((tariffProperty, index) => (
                <li className={s.tariffCardListItem} key={index}>
                  <IconTariffsCheckmark
                    className={s.tariffCardListItemIcon}
                  />
                  <span>{tariffProperty}</span>
                </li>
              ))}
            </ul>

            <div className={s.tariffCardActions}>
              <div className={s.tariffCardActionLink}>
                <a
                  className="text-blue"
                  onClick={() => yaEvent('pressed_button_pricing')}
                  href={
                    '/doc/СберПодбор_Лицензионный_договор_публичная_оферта_ООО_РДВ-софт.pdf'
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Полное описание тарифа
                </a>
              </div>
            </div>
          </>}
        </div>
      </div>

      <p className={cn(s.tariffsFootnote, s.tariffsFootnoteFirst, { [s.tariffsFootnoteCenter]: isIndexPage })}>
        * Для целей тарифа срок действия 1 месяц принимается равным 30 дням,
        3 месяца — 91 дню, 6 месяцев — 182 дням, год — 364 дням.
      </p>
      <p className={cn(s.tariffsFootnote, s.tariffsFootnoteLast, { [s.tariffsFootnoteCenter]: isIndexPage })}>
        **Срок действия акции ограничен. Подробные условия <a href="https://promo.sberpodbor.ru/optima30" target='_blank' rel="noreferrer" className="text-blue">по ссылке</a>.
      </p>
      {
        isIndexPage &&
        <p className={cn(s.tariffsFootnote, s.tariffsFootnoteCenter)}>
          Более подробная информация о составе тарифов <a href='/tariffs' className='text-blue'>здесь</a>.
        </p>
      }
    </div>
  );
};

export default tariffList;
