// extracted by mini-css-extract-plugin
export var tariffs = "tariffList-module--tariffs--CTPoQ";
export var tariffsIndex = "tariffList-module--tariffsIndex--Dzg7N";
export var tariffsTitle = "tariffList-module--tariffsTitle--L1fvT";
export var tariffsSubtitle = "tariffList-module--tariffsSubtitle--w8uW-";
export var tariffsBackground_1 = "tariffList-module--tariffsBackground_1--nFdbP";
export var tariffsBackground_2 = "tariffList-module--tariffsBackground_2--MyyOo";
export var tariffsCards = "tariffList-module--tariffsCards--J0l8s";
export var tariffCard = "tariffList-module--tariffCard--5Y4CY";
export var tariffCardOnlyMain = "tariffList-module--tariffCardOnlyMain--4v3Mz";
export var tariffCardStandardMini = "tariffList-module--tariffCardStandardMini--qgVAp";
export var tariffCardMainPart = "tariffList-module--tariffCardMainPart--8ClqB";
export var tariffCardMainPartMini = "tariffList-module--tariffCardMainPartMini--7EJcx";
export var tariffCardName = "tariffList-module--tariffCardName--eKVqm";
export var tariffCardDescription = "tariffList-module--tariffCardDescription--vNASe";
export var tariffPrice = "tariffList-module--tariffPrice--7XCJl";
export var tariffPriceButton = "tariffList-module--tariffPriceButton--BEMmX";
export var tariffCardListItem = "tariffList-module--tariffCardListItem--GBXU1";
export var tariffCardListItemIcon = "tariffList-module--tariffCardListItemIcon--RtyIA";
export var tariffCardActions = "tariffList-module--tariffCardActions--ugk4F";
export var tariffCardActionLink = "tariffList-module--tariffCardActionLink--tDQoY";
export var tariffCardWithDiscount = "tariffList-module--tariffCardWithDiscount--6G2+8";
export var tariffsFootnote = "tariffList-module--tariffsFootnote--OWgCe";
export var tariffsFootnoteFirst = "tariffList-module--tariffsFootnoteFirst--ShWRi";
export var tariffsFootnoteLast = "tariffList-module--tariffsFootnoteLast--0VCCT";
export var tariffsFootnoteCenter = "tariffList-module--tariffsFootnoteCenter--m4CWl";