export function setCookie (key, value, options = {}) {
  if (typeof document === 'undefined') {
    return;
  }

  if (typeof options.expires === 'number') {
    options.expires = new Date(new Date() * 1 + options.expires * 864e+5);
  }

  options.expires = options.expires ? options.expires.toUTCString() : '';

  try {
    const result = JSON.stringify(value);

    if (/^[\{\[]/.test(result)) {
      value = result;
    }
  } catch (e) {}

  value = encodeURIComponent(String(value))
      .replace(/%(23|24|26|2B|3A|3C|3E|3D|2F|3F|40|5B|5D|5E|60|7B|7D|7C)/g, decodeURIComponent);

  key = String(key)
    .replace(/%(23|24|26|2B|5E|60|7C)/g, decodeURIComponent)
    .replace(/[\(\)]/g, escape);

  let stringifiedAttributes = '';
  for (const option in options) {
    if (!options[option]) {
      continue;
    }
    stringifiedAttributes += '; ' + option;
    if (options[option] === true) {
      continue;
    }

    // Considers RFC 6265 section 5.2:
    // ...
    // 3.  If the remaining unparsed-attributes contains a %x3B (";")
    //     character:
    // Consume the characters of the unparsed-attributes up to,
    // not including, the first %x3B (";") character.
    // ...
    stringifiedAttributes += '=' + options[option].split(';')[0];
  }

  return (document.cookie = key + '=' + value + stringifiedAttributes);
}
