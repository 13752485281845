import React from 'react';
import cn from 'classnames';

import * as s from './section.module.scss';

const Section = React.forwardRef(({ children, id, className }, ref) => (
  <section ref={ref} className={cn(s.section, s[className])} id={id}>
    { children }
  </section>
));

export default Section;
